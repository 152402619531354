import {Component} from "react";
import simplyContext from "../../../../settings/state/simply";
import withRouter from "../../../main/withRouter"
import {funcNavigate} from "../../../../settings/utils/functions";
import {Helmet, HelmetProvider} from "react-helmet-async";
import {header} from "../../components/header";
import {footer} from "../../components/footer";
import {Link, NavLink} from "react-router-dom";
import {urlDynamic} from "../../../../settings/utils/urlDynamic";
import PaginationFilter from "../../components/PaginationFilter";

class Talifat extends Component {
    static contextType = simplyContext

    componentDidMount() {
this.context.fSettings('fTalifat')
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        funcNavigate(this.props.navigate, this.context)
    }


    render() {
        return (
            <HelmetProvider>
                <Helmet>
                   <title>دکتر شهرام یزدانی / تالیفات</title>
                    <meta name="description" content=''/>
                </Helmet>

                <div
                    className={`container-fluid ${this.context.state.sThemeMode === 'cDark' ? "cBgDark text-white" : "bg-light text-dark"} min-vh-100 ${this.context.state.sThemeColor}`}>

                    {header(this.context)}

                     {this.context.state.sTalifat !== null ? (
                    <main>

                        <section>
                            <div className="row px-2 px-md-5 my-4 mb-5">
                                <div className='col-12'>
                                    <div className='mb-3'>
                                        <NavLink to={urlDynamic.path.safheAsli}
                                                 className={(e) => e['isActive'] === true ? `${this.context.state.sThemeMode === 'cDark' ? "text-white" : "text-dark"}` : `${this.context.state.sThemeMode === 'cDark' ? "text-white" : "text-dark"} cHover3 cTransition`}>صفحه
                                            اصلی</NavLink>
                                        <span className='mx-2'>/</span>
                                        <span className='fw-bold'>تالیفات</span>
                                    </div>
                                     {this.context.state.sTalifat.ser !== null ? (
                                    <div className='cThemeBg1 cThemeBg2 bg-gradient rounded-4 cBoxShadow1'>
                                        <div className='row align-items-center py-3 px-4'>
                                            <div className='col-12 overflow-hidden'>
                                                    <div className="table-responsive lh-lg">
                                                        <table className="table text-center cThemeColor1 cThemeColor3 align-middle">
                                                            <thead>
                                                            <tr className='border-bottom cThemeBorder1 cThemeBorder2 cThemeColor2 cAviny border-1'>
                                                                <th className='tablThDefault px-1 fw-normal' scope="col">ردیف</th>
                                                                <th className='tablThDefault cPadding2 fw-normal' scope="col">عنوان</th>
                                                                <th className='tablThDefault cPadding2 fw-normal' scope="col">انتشارات</th>
                                                                <th className='tablThDefault cPadding2 fw-normal' scope="col">سایر مولفین</th>
                                                                <th className='tablThDefault px-5 px-lg-2 fw-normal' scope="col">سال انتشار</th>
                                                                <th className='tablThDefault px-3 fw-normal' scope="col">تصویر</th>
                                                                 <th className='tablThDefault px-1 fw-normal' scope="col">فایل ضمیمه</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody className='cFontSize12'>
                                                             {this.context.state.sTalifat.ser.map((p, index) => (
                                                            <tr key={index} className='border-bottom cThemeBorder1 cThemeBorder2 border-1'>
                                                                <th scope="row">
                                                                                                                                                    {(index + (this.context.state.sTalifat.pageNumber - 1) * this.context.state.sTalifat.pageSize) + 1}
                                                                </th>
                                                                <td>{p.title || '-'}</td>
                                                                <td>{p.entesharat || '-'}</td>
                                                                <td>{p.sayer || '-'}</td>
                                                                <td>{p.tarikh || '-'}</td>
                                                                  <td>
                                                                    {p.image === null ? '-' : (
                                                                       <Link className='cThemeColor1 cThemeColor3 cThemeHover1' to={p.image} target='_blank'>
                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                             width="16" height="16" fill="currentColor"
                                                                             className="bi bi-eye-fill cCursorPointer"
                                                                             viewBox="0 0 16 16">
                                                                            <path
                                                                                d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0"/>
                                                                            <path
                                                                                d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8m8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7"/>
                                                                        </svg>
                                                                    </Link>
                                                                    )}
                                                                </td>
                                                                          <td>
                                                                    {p.zamime === null ? '-' : (
                                                                       <Link className='cThemeColor1 cThemeColor3 cThemeHover1' to={p.zamime} target='_blank'>
                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                             width="16" height="16" fill="currentColor"
                                                                             className="bi bi-eye-fill cCursorPointer"
                                                                             viewBox="0 0 16 16">
                                                                            <path
                                                                                d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0"/>
                                                                            <path
                                                                                d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8m8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7"/>
                                                                        </svg>
                                                                    </Link>
                                                                    )}
                                                                </td>
                                                            </tr>
                                                                     ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                     <div className='my-3'>
                                                                 <PaginationFilter func={this.context.fTalifat}
                                                                                   state={this.context.state.sTalifat}/>
                                                        </div>
                                            </div>
                                        </div>
                                    </div>
                                          ) : null}
                                </div>
                            </div>
                        </section>

                    </main>
                         ) : null}

                    {footer(this.context)}


                </div>


            </HelmetProvider>
        )
    }
}

export default withRouter(Talifat)

