import {NavLink} from "react-router-dom";
import {urlDynamic} from "../../../settings/utils/urlDynamic";
import { LazyLoadImage } from 'react-lazy-load-image-component';

export const footer = (context) => {
    return (
        <footer>
            <div className='row mt-2 px-2 px-md-5 py-3 cThemeBg1 align-items-center'>
                <div className='col-12 col-lg-4 text-center'>
                    <LazyLoadImage effect="blur" src='/images/image2.webp' className='img-fluid m-auto cPreview1 w-75 cWidth50_991 d-none' alt='تصویر'/>
                    <LazyLoadImage effect="blur" src='/images/image1.webp' className='img-fluid m-auto cPreview2 w-75 cWidth50_991 d-none' alt='تصویر'/>
                </div>
                <div className='col-12 col-lg-8 text-center'>
                    <div className='row'>
                        {context.state.sMenu.map((p, index) => (
                            <div key={index} className='col-6 col-sm-3 cThemeColor1 text-center'>
                                <NavLink
                                    to={urlDynamic.path[p.path]}
                                    className={(e) => e['isActive'] === true ? "d-inline-block py-3 cCursorPointer cThemeColor2 fw-bold" : "cThemeColor1 d-inline-block py-3 cCursorPointer cThemeHover1 cTransition"}>
                                    {p.title}
                                </NavLink>
                            </div>
                        ))}
                    </div>
                </div>
                <div className='col-12'>
                    <hr className='cThemeColor1'/>
                    <p className='cLineHeight2 mb-0 text-center cThemeColor1'>تمام حقوق معنوی این
                        سایت
                        متعلق به آقای دکتر شهرام یزدانی می باشد
                    </p>
                </div>
            </div>
        </footer>
    )
}