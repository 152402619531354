import {Offcanvas} from "react-bootstrap";
import {NavLink} from "react-router-dom";
import {urlDynamic} from "../../../settings/utils/urlDynamic";

export const menu = (context) => {
    return (
        <Offcanvas backdrop="false" className={`cOffConvans ${context.state.sThemeColor === null ? "cTheme15": context.state.sThemeColor}`}
                   show={context.state.sOffConvas}
                   onHide={context.fOffConvas}>
            <Offcanvas.Header className='justify-content-end cThemeBg1 cThemeColor1'>
                <svg onClick={() => context.fOffConvas()} xmlns="http://www.w3.org/2000/svg" width="23"
                     height="23" fill="currentColor"
                     className="bi bi-x-lg cCursorPointer cHover2 cTransition cThemeColor1" viewBox="0 0 16 16">
                    <path
                        d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
                </svg>
            </Offcanvas.Header>
            <Offcanvas.Body dir='ltr' className='cThemeBg1 cThemeColor1'>
                <div className='row text-start'>
                    <div className='col-12 p-3'>
                        {context.state.sMenu.map((p, index) => (
                            <NavLink key={index}
                                     to={urlDynamic.path[p.path]}
                                     className={(e) => e['isActive'] === true ? "cThemeColor1 d-block py-2 cCursorDefault cThemeColor2 fw-bold" : "cThemeColor1 d-block py-2 cCursorPointer cThemeHover1 cTransition"}>
                                {p.title}
                            </NavLink>
                        ))}
                    </div>
                </div>
            </Offcanvas.Body>
        </Offcanvas>
    )
}