import {Component} from "react";
import simplyContext from "../../../../settings/state/simply";
import withRouter from "../../../main/withRouter"
import {funcNavigate} from "../../../../settings/utils/functions";
import {Helmet, HelmetProvider} from "react-helmet-async";
import {header} from "../../components/header";
import {footer} from "../../components/footer";
import {NavLink} from "react-router-dom";
import {urlDynamic} from "../../../../settings/utils/urlDynamic";
import { LazyLoadImage } from 'react-lazy-load-image-component';

class DarsGoftar extends Component {
    static contextType = simplyContext

    componentDidMount() {
this.context.fSettings('fDarsGoftar')
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        funcNavigate(this.props.navigate, this.context)
    }


    render() {
        return (
            <HelmetProvider>
                <Helmet>
                     <title>دکتر شهرام یزدانی / درسگفتارها</title>
                    <meta name="description" content=''/>
                </Helmet>

                <div
                    className={`container-fluid ${this.context.state.sThemeMode === 'cDark' ? "cBgDark text-white" : "bg-light text-dark"} min-vh-100 ${this.context.state.sThemeColor}`}>

                    {header(this.context)}

                    <main>

                        <section>
                            <div className="row px-2 px-md-5 my-4 mb-5">
                                <div className='col-12'>
                                    <div className='mb-3'>
                                        <NavLink to={urlDynamic.path.safheAsli}
                                                 className={(e) => e['isActive'] === true ? `${this.context.state.sThemeMode === 'cDark' ? "text-white" : "text-dark"}` : `${this.context.state.sThemeMode === 'cDark' ? "text-white" : "text-dark"} cHover3 cTransition`}>صفحه
                                            اصلی</NavLink>
                                        <span className='mx-2'>/</span>
                                        <span className='fw-bold'>درسگفتارها</span>
                                    </div>
                                    <div className='cThemeBg1 cThemeBg2 bg-gradient rounded-4 cBoxShadow1 overflow-hidden'>
                                        <div className='row py-3 px-4'>
                                            <div className='col-12'>
                                                    <div className='row mt-2 py-5 px-2 px-md-5'>
                                                        <div
                                                            className='col-12 mb-4 col-sm-6 col-lg mb-lg-0 text-center'>
                                                            <LazyLoadImage effect="blur" src='/images/dars-goftar/image2.webp'
                                                                 className='img-fluid cWidth50_991 d-block m-auto'
                                                                 alt='تصویر'/>
                                                            <NavLink to={urlDynamic.path.marjaiatElmi} className='cThemeColor1 cThemeColor3 d-inline-block cLineHeight2 mt-2 cCursorPointer cThemeHover1 cTransition'>
                                                                مجموعه سخنرانی مرجعیت علمی
                                                            </NavLink>
                                                        </div>
                                                        <div
                                                            className='col-12 mb-4 col-sm-6 col-lg mb-lg-0 text-center'>
                                                            <LazyLoadImage effect="blur" src='/images/dars-goftar/image5.webp'
                                                                 className='img-fluid cWidth50_991 d-block m-auto'
                                                                 alt='تصویر'/>
                                                           <NavLink to={urlDynamic.path.goftarHayeRahbordi} className='cThemeColor1 cThemeColor3 d-inline-block cLineHeight2 mt-2 cCursorPointer cThemeHover1 cTransition'>
                                                               گفتارهای راهبردی در حوزه
سیاستگذاری سلامت
                                                            </NavLink>
                                                        </div>
                                                        <div
                                                            className='col-12 mb-4 col-sm-6 col-lg mb-lg-0 text-center'>
                                                            <LazyLoadImage effect="blur" src='/images/dars-goftar/image3.webp'
                                                                 className='img-fluid cWidth50_991 d-block m-auto'
                                                                 alt='تصویر'/>
                                                             <NavLink to={urlDynamic.path.bastehayeAmozeshiAnjomanha} className='cThemeColor1 cThemeColor3 d-inline-block cLineHeight2 mt-2 cCursorPointer cThemeHover1 cTransition'>
                                                                بسته های آموزشی انجمن ها
                                                            </NavLink>
                                                        </div>
                                                        <div
                                                            className='col-12 mb-4 mb-sm-0 col-sm-6 col-lg text-center'>
                                                            <LazyLoadImage effect="blur" src='/images/dars-goftar/image4.webp'
                                                                 className='img-fluid cWidth50_991 d-block m-auto'
                                                                 alt='تصویر'/>
                                                            <NavLink to={urlDynamic.path.erteghaAzaeElmi} className='cThemeColor1 cThemeColor3 d-inline-block cLineHeight2 mt-2 cCursorPointer cThemeHover1 cTransition'>
                                                                 ارتقاء اعضای هیات علمی
                                                            </NavLink>
                                                        </div>
                                                        <div
                                                            className='col-12 text-center col-sm-6 offset-sm-3 col-lg offset-lg-0'>
                                                            <LazyLoadImage effect="blur" src='/images/dars-goftar/image1.webp'
                                                                 className='img-fluid cWidth50_991 d-block m-auto'
                                                                 alt='تصویر'/>
                                                             <NavLink to={urlDynamic.path.bastehayeAmoozeshi} className='cThemeColor1 cThemeColor3 d-inline-block cLineHeight2 mt-2 cCursorPointer cThemeHover1 cTransition'>
                                                                بسته های آموزشی
                                                            </NavLink>
                                                            <small className='cThemeColor1 cThemeColor3 d-inline-block mt-1 cFontSize12'>ویژه
                                                                اعضای هیات علمی و مدیران نظام
                                                                سلامت</small>
                                                        </div>

                                                    </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                    </main>

                    {footer(this.context)}


                </div>


            </HelmetProvider>
        )
    }
}

export default withRouter(DarsGoftar)

