import React, {Component} from "react";
import simplyContext from "./simply";
import axios from "axios";
import {BASE_URL} from "../utils/config";
import {TIMEOUT} from "../utils/config";
import {toastAlerts} from "../utils/toastify";
import {translation} from "../translate/fa";
import {urlDynamic} from "../utils/urlDynamic";


class GlobalState extends Component {
    state = {
        sNavigate: null,
        sPreloader: false,
        sThemeMode: (sessionStorage.getItem('mode') === 'dark' ? "cDark" : "cLight"),
        sThemeColor: sessionStorage.getItem('theme'),
        sShowModal: '',
        sOffConvas: false,
        sMenu: [
            {'title': 'صفحه اصلی', 'path': 'safheAsli'}, {'title': 'بیوگرافی', 'path': 'bio'},
            {'title': 'سوابق مدیریتی', 'path': 'savebeghModiriati'}, {'title': 'افتخارات', 'path': 'eftekharat'},
            {'title': 'اسلایدها', 'path': 'slide'}, {'title': 'سوابق رسانه ای', 'path': 'savabeghResanei'},
            {'title': 'مقالات فارسی', 'path': 'maghalatFa'}, {'title': 'مقالات انگلیسی', 'path': 'maghalatEn'},
            {'title': 'تالیفات', 'path': 'talifat'}, {'title': 'درسگفتارها', 'path': 'darsGoftar'},
            {'title': 'ارتباط با ما', 'path': 'ertebatBaMa'}, {'title': 'تالار گفتگو', 'path': 'talarGoftegoo'},
        ],
        sSettings: null,
        sHome: null,
        sBio: null,
        sSavabegh: null,
        sEftekharat: null,
        sSavabeghResanei: null,
        sMaghalatFarsi: null,
        sMaghalatEn: null,
        sTalifat: null,
    }

    fResetNavigate = () => {
        this.setState({sNavigate: null})
    }

    fNavigate = (path) => {
        this.setState({sNavigate: path})
    }

    fPreloader = (show) => {
        this.setState({sPreloader: show})
    }

    fThemeMode = (e) => {
        if (e === true) {
            this.setState({sThemeMode: 'cDark'})
            sessionStorage.setItem('mode', 'dark')
        } else {
            this.setState({sThemeMode: 'cLight'})
            sessionStorage.setItem('mode', 'light')
        }
    }

    fThemeColor = (e) => {
        this.setState({sThemeColor: e})
        sessionStorage.setItem('theme', e)
        this.fShowModal('')
    }

    fShowModal = (id) => {
        this.setState({sShowModal: id})
    }

    fOffConvas = () => {
        this.setState({sOffConvas: !this.state.sOffConvas})
    }

    fReset = () => {
        window.scrollTo(0, 0);
        this.setState({sOffConvas: false})
    }


    fNotPermission = (settings) => {
        if (settings.response === undefined) {
            toastAlerts({'data': {'code': 'error', "message": translation.msgError500}})
            this.fNavigate(urlDynamic.path.error500)
            console.log('error: Backend is down')
        } else {
            if (settings.response.status === 401) {
                toastAlerts({'data': {'code': 'error', "message": translation.msgError401}})
                this.fNavigate(urlDynamic.path.error500)
                console.log('error: first login')
            } else if (settings.response.status === 500 && settings.response.data.redirect === "true") {
                toastAlerts({'data': {'code': 'error', "message": settings.response.data.message}})
                this.fNavigate(urlDynamic.path.error500)
            } else {
                if (settings.response.status === 404) {
                    toastAlerts({'data': {'code': 'error', "message": translation.msgError500}})
                    this.fNavigate(urlDynamic.path.error500)
                    console.log('error: Backend url 404')
                }
            }
        }
    }

    fSettings = (callFunction = null) => {
        this.fPreloader(true)
        this.fReset()
        if (this.state.sSettings === null) {
            axios.get(
                BASE_URL + '/api/v1/settings/', {
                    timeout: TIMEOUT,
                    headers: {
                        "Content-Type": "application/json",
                    }
                }
            ).then((response) => {
                this.setState({sSettings: response.data.data})
                if (sessionStorage.getItem('theme') === null) {
                    this.setState({sThemeColor: response.data.data.ser.theme})
                }
                if (sessionStorage.getItem('mode') === null) {
                    this.setState({sThemeMode: response.data.data.ser.darkLight})
                }
                this[callFunction]();
            }).catch(error => {
                toastAlerts(error.response)
                this.fNotPermission(error)
                this.fPreloader(false)
            })
        } else {
            this[callFunction]();
        }
    }


    fBastehayeAmoozeshi = () => {
        this.fPreloader(false)
    }

    fBateHayeAmoozeshiAnjomanHa = () => {
        this.fPreloader(false)
    }

    fBio = () => {
        axios.get(
            BASE_URL + '/api/v1/page/bio/', {
                timeout: TIMEOUT,
                headers: {
                    "Content-Type": "application/json",
                }
            }
        ).then((response) => {
            this.setState({sBio: response.data.data})
            this.fPreloader(false)
        }).catch(error => {
            toastAlerts(error.response)
            this.fNotPermission(error)
            this.fPreloader(false)
        })
    }

    fDarsGoftar = () => {
        this.fPreloader(false)
    }

    fEftekharat = (params={}) => {
         axios.get(
            BASE_URL + '/api/v1/page/honors/', {
                timeout: TIMEOUT,
                params: params,
                headers: {
                    "Content-Type": "application/json",
                }
            }
        ).then((response) => {
            this.setState({sEftekharat: response.data.data})
            this.fPreloader(false)
        }).catch(error => {
            toastAlerts(error.response)
            this.fNotPermission(error)
            this.fPreloader(false)
        })
    }

    fErteghaAzaeHeyatElmi = () => {
        this.fPreloader(false)
    }

    fGoftarhayeRahbordi = () => {
        this.fPreloader(false)
    }

    fHome = () => {
        axios.get(
            BASE_URL + '/api/v1/page/home/', {
                timeout: TIMEOUT,
                headers: {
                    "Content-Type": "application/json",
                }
            }
        ).then((response) => {
            this.setState({sHome: response.data.data})
            this.fPreloader(false)
        }).catch(error => {
            toastAlerts(error.response)
            this.fNotPermission(error)
            this.fPreloader(false)
        })
    }

    fMaghalatEn = (params={}) => {
        axios.get(
            BASE_URL + '/api/v1/page/english-articles/', {
                timeout: TIMEOUT,
                params: params,
                headers: {
                    "Content-Type": "application/json",
                }
            }
        ).then((response) => {
            this.setState({sMaghalatEn: response.data.data})
            this.fPreloader(false)
        }).catch(error => {
            toastAlerts(error.response)
            this.fNotPermission(error)
            this.fPreloader(false)
        })
    }

    fMaghalatFarsi = (params={}) => {
         axios.get(
            BASE_URL + '/api/v1/page/persian-articles/', {
                timeout: TIMEOUT,
                params: params,
                headers: {
                    "Content-Type": "application/json",
                }
            }
        ).then((response) => {
            this.setState({sMaghalatFarsi: response.data.data})
            this.fPreloader(false)
        }).catch(error => {
            toastAlerts(error.response)
            this.fNotPermission(error)
            this.fPreloader(false)
        })
    }

    fMarjaiatElmi = () => {
        this.fPreloader(false)
    }

    fRahErtebati = (params={}) => {
        this.fPreloader(false)
    }

    fSavabeghModiriati = (params={}) => {
        axios.get(
            BASE_URL + '/api/v1/page/management-records/', {
                timeout: TIMEOUT,
                params: params,
                headers: {
                    "Content-Type": "application/json",
                }
            }
        ).then((response) => {
            this.setState({sSavabegh: response.data.data})
            this.fPreloader(false)
        }).catch(error => {
            toastAlerts(error.response)
            this.fNotPermission(error)
            this.fPreloader(false)
        })
    }

    fSavabeghResanei = (params={}) => {
        axios.get(
            BASE_URL + '/api/v1/page/media-records/', {
                timeout: TIMEOUT,
                params: params,
                headers: {
                    "Content-Type": "application/json",
                }
            }
        ).then((response) => {
            this.setState({sSavabeghResanei: response.data.data})
            this.fPreloader(false)
        }).catch(error => {
            toastAlerts(error.response)
            this.fNotPermission(error)
            this.fPreloader(false)
        })
    }

    fSlideHa = () => {
        this.fPreloader(false)
    }

    fTalarGoftegoo = () => {
        this.fPreloader(false)
    }

    fTalifat = (params={}) => {
        axios.get(
            BASE_URL + '/api/v1/page/compilations/', {
                timeout: TIMEOUT,
                params: params,
                headers: {
                    "Content-Type": "application/json",
                }
            }
        ).then((response) => {
            this.setState({sTalifat: response.data.data})
            this.fPreloader(false)
        }).catch(error => {
            toastAlerts(error.response)
            this.fNotPermission(error)
            this.fPreloader(false)
        })
    }


    render() {
        return (
            <simplyContext.Provider value={{
                state: this.state,
                fResetNavigate: this.fResetNavigate,
                fNavigate: this.fNavigate,
                fPreloader: this.fPreloader,
                fThemeMode: this.fThemeMode,
                fShowModal: this.fShowModal,
                fThemeColor: this.fThemeColor,
                fOffConvas: this.fOffConvas,
                fReset: this.fReset,
                fNotPermission: this.fNotPermission,
                fSettings: this.fSettings,
                fBastehayeAmoozeshi: this.fBastehayeAmoozeshi,
                fBateHayeAmoozeshiAnjomanHa: this.fBateHayeAmoozeshiAnjomanHa,
                fBio: this.fBio,
                fDarsGoftar: this.fDarsGoftar,
                fEftekharat: this.fEftekharat,
                fErteghaAzaeHeyatElmi: this.fErteghaAzaeHeyatElmi,
                fGoftarhayeRahbordi: this.fGoftarhayeRahbordi,
                fHome: this.fHome,
                fMaghalatEn: this.fMaghalatEn,
                fMaghalatFarsi: this.fMaghalatFarsi,
                fMarjaiatElmi: this.fMarjaiatElmi,
                fRahErtebati: this.fMarjaiatElmi,
                fSavabeghModiriati: this.fSavabeghModiriati,
                fSavabeghResanei: this.fSavabeghResanei,
                fSlideHa: this.fSlideHa,
                fTalarGoftegoo: this.fTalarGoftegoo,
                fTalifat: this.fTalifat,
            }}>
                {this.props.children}
            </simplyContext.Provider>
        )
    }
}

export default GlobalState
