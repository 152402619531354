import GlobalState from "./settings/state/GlobalState";
import MainApp from "./app/main/MainApp";
import ReactDOM from 'react-dom/client';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <GlobalState>
    <MainApp />
  </GlobalState>
);
