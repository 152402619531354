import {Modal} from "react-bootstrap";

export const modalChangeTheme = (context) => {
    return (
        <Modal
            show={context.state.sShowModal === 'modalChangeTheme'}
            onHide={() => context.fShowModal('')}
            animation={false}
            backdrop="static"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            keyboard={false}
            size="sm"
            contentClassName={`${context.state.sThemeMode === 'cDark' ? "bg-dark text-white" : "bg-light text-dark"} cBoxShadow1 border-0`}
            className="cZindex9999 d-block">
            <Modal.Header className='py-1 border-0'>
                تغییر تم
                <span onClick={() => context.fShowModal('')}
                      className='float-end fs-4 cHover2 cTransition cCursorPointer'>
                                                        x
                                                    </span>
            </Modal.Header>
            <Modal.Body>
                <div className='row'>
                     <div className='col-3 text-center my-2'>
                        <div onClick={() => context.fThemeColor('cTheme15')}
                             className='rounded-circle cThemeBox m-auto cCursorPointer cHover2 cTransition cBgTheme15'>&nbsp;</div>
                    </div>
                    <div className='col-3 text-center my-2'>
                        <div onClick={() => context.fThemeColor('cTheme5')}
                             className='rounded-circle cThemeBox m-auto cCursorPointer cHover2 cTransition cBgTheme5'>&nbsp;</div>
                    </div>
                     <div className='col-3 text-center my-2'>
                        <div onClick={() => context.fThemeColor('cTheme2')}
                             className='rounded-circle cThemeBox m-auto cCursorPointer cHover2 cTransition cBgTheme2'>&nbsp;</div>
                    </div>
                    <div className='col-3 text-center my-2'>
                        <div onClick={() => context.fThemeColor('cTheme1')}
                             className='rounded-circle cThemeBox m-auto cCursorPointer cHover2 cTransition cBgTheme1'>&nbsp;</div>
                    </div>
                    <div className='col-3 text-center my-2'>
                        <div onClick={() => context.fThemeColor('cTheme6')}
                             className='rounded-circle cThemeBox m-auto cCursorPointer cHover2 cTransition cBgTheme6'>&nbsp;</div>
                    </div>
                    <div className='col-3 text-center my-2'>
                        <div onClick={() => context.fThemeColor('cTheme16')}
                             className='rounded-circle cThemeBox m-auto cCursorPointer cHover2 cTransition cBgTheme16'>&nbsp;</div>
                    </div>

                     <div className='col-3 text-center my-2'>
                        <div onClick={() => context.fThemeColor('cTheme10')}
                             className='rounded-circle cThemeBox m-auto cCursorPointer cHover2 cTransition cBgTheme10'>&nbsp;</div>
                    </div>

                    <div className='col-3 text-center my-2'>
                        <div onClick={() => context.fThemeColor('cTheme7')}
                             className='rounded-circle cThemeBox m-auto cCursorPointer cHover2 cTransition cBgTheme7'>&nbsp;</div>
                    </div>

                </div>
            </Modal.Body>
        </Modal>
    )
}