import {Component} from "react";
import simplyContext from "../../../../settings/state/simply";
import withRouter from "../../../main/withRouter"
import {funcNavigate} from "../../../../settings/utils/functions";
import {Helmet, HelmetProvider} from "react-helmet-async";
import {header} from "../../components/header";
import {footer} from "../../components/footer";
import {NavLink} from "react-router-dom";
import {urlDynamic} from "../../../../settings/utils/urlDynamic";

class BastehayeAmoozeshi extends Component {
    static contextType = simplyContext

    componentDidMount() {
this.context.fSettings('fBastehayeAmoozeshi')
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        funcNavigate(this.props.navigate, this.context)
    }


    render() {
        return (
            <HelmetProvider>
                <Helmet>
                      <title>دکتر شهرام یزدانی / بسته های آموزشی</title>
                    <meta name="description" content=''/>
                </Helmet>

                <div
                    className={`container-fluid ${this.context.state.sThemeMode === 'cDark' ? "cBgDark text-white" : "bg-light text-dark"} text-dark min-vh-100 ${this.context.state.sThemeColor}`}>

                    {header(this.context)}

                    <main>

                        <section>
                            <div className="row px-2 px-md-5 my-4 mb-5">
                                <div className='col-12'>
                                    <div className='mb-3'>
                                        <NavLink to={urlDynamic.path.safheAsli}
                                                 className={(e) => e['isActive'] === true ? `${this.context.state.sThemeMode === 'cDark' ? "text-white" : "text-dark"}` : `${this.context.state.sThemeMode === 'cDark' ? "text-white" : "text-dark"} cHover3 cTransition`}>صفحه
                                            اصلی</NavLink>
                                        <span className='mx-2'>/</span>
                                        <NavLink to={urlDynamic.path.darsGoftar}
                                                 className={(e) => e['isActive'] === true ? `${this.context.state.sThemeMode === 'cDark' ? "text-white" : "text-dark"}` : `${this.context.state.sThemeMode === 'cDark' ? "text-white" : "text-dark"} cHover3 cTransition`}>درسگفتارها</NavLink>
                                        <span className='mx-2'>/</span>
                                        <span className='fw-bold'>بسته های آموزشی</span>
                                    </div>
                                    {/*<div className='cThemeBg1 cThemeBg2 bg-gradient rounded-4 cBoxShadow1'>*/}
                                    {/*    <div className='row align-items-center py-3 px-4'>*/}
                                    {/*        <div className='col-12 overflow-hidden'>*/}
                                    {/*                <div className="table-responsive lh-lg">*/}
                                    {/*                    <table className="table text-center cThemeColor1 cThemeColor3 align-middle">*/}
                                    {/*                        <thead>*/}
                                    {/*                        <tr className='border-bottom cThemeBorder1 cThemeBorder2 cThemeColor2 cAviny border-1'>*/}
                                    {/*                            <th className='tablThDefault px-1 fw-normal' scope="col">ردیف</th>*/}
                                    {/*                            <th className='tablThDefault cPadding2 fw-normal' scope="col">عنوان</th>*/}
                                    {/*                            <th className='tablThDefault cPadding2 fw-normal' scope="col">سخنران</th>*/}
                                    {/*                            <th className='tablThDefault px-1 fw-normal' scope="col">بسته آموزشی</th>*/}
                                    {/*                            <th className='tablThDefault px-1 fw-normal' scope="col">پاورپوینت</th>*/}
                                    {/*                        </tr>*/}
                                    {/*                        </thead>*/}
                                    {/*                        <tbody className='cFontSize12'>*/}
                                    {/*                        <tr className='border-bottom cThemeBorder1 cThemeBorder2 border-1'>*/}
                                    {/*                            <th scope="row">1</th>*/}
                                    {/*                            <td>عضو شورای سیاستگذاری علمی نوین پزشکی</td>*/}
                                    {/*                            <td>دکتر سید شهاب الدین صدر ـ رئیس کل سازمان نظام*/}
                                    {/*                                پزشکی*/}
                                    {/*                            </td>*/}
                                    {/*                            <td>*/}
                                    {/*                                <svg*/}
                                    {/*                                    xmlns="http://www.w3.org/2000/svg" width="16"*/}
                                    {/*                                    height="16" fill="currentColor"*/}
                                    {/*                                    className="bi bi-download cCursorPointer"*/}
                                    {/*                                    viewBox="0 0 16 16">*/}
                                    {/*                                    <path*/}
                                    {/*                                        d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5"/>*/}
                                    {/*                                    <path*/}
                                    {/*                                        d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z"/>*/}
                                    {/*                                </svg>*/}
                                    {/*                            </td>*/}
                                    {/*                            <td>*/}
                                    {/*                                <svg*/}
                                    {/*                                    xmlns="http://www.w3.org/2000/svg" width="16"*/}
                                    {/*                                    height="16" fill="currentColor"*/}
                                    {/*                                    className="bi bi-download cCursorPointer"*/}
                                    {/*                                    viewBox="0 0 16 16">*/}
                                    {/*                                    <path*/}
                                    {/*                                        d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5"/>*/}
                                    {/*                                    <path*/}
                                    {/*                                        d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z"/>*/}
                                    {/*                                </svg>*/}
                                    {/*                            </td>*/}
                                    {/*                        </tr>*/}
                                    {/*                        <tr className='border-bottom cThemeBorder1 cThemeBorder2 border-1'>*/}
                                    {/*                            <th scope="row">1</th>*/}
                                    {/*                            <td>عضو شورای سیاستگذاری علمی نوین پزشکی</td>*/}
                                    {/*                            <td>دکتر سید شهاب الدین صدر ـ رئیس کل سازمان نظام*/}
                                    {/*                                پزشکی*/}
                                    {/*                            </td>*/}
                                    {/*                            <td>*/}
                                    {/*                                <svg*/}
                                    {/*                                    xmlns="http://www.w3.org/2000/svg" width="16"*/}
                                    {/*                                    height="16" fill="currentColor"*/}
                                    {/*                                    className="bi bi-download cCursorPointer"*/}
                                    {/*                                    viewBox="0 0 16 16">*/}
                                    {/*                                    <path*/}
                                    {/*                                        d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5"/>*/}
                                    {/*                                    <path*/}
                                    {/*                                        d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z"/>*/}
                                    {/*                                </svg>*/}
                                    {/*                            </td>*/}
                                    {/*                            <td>*/}
                                    {/*                                <svg*/}
                                    {/*                                    xmlns="http://www.w3.org/2000/svg" width="16"*/}
                                    {/*                                    height="16" fill="currentColor"*/}
                                    {/*                                    className="bi bi-download cCursorPointer"*/}
                                    {/*                                    viewBox="0 0 16 16">*/}
                                    {/*                                    <path*/}
                                    {/*                                        d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5"/>*/}
                                    {/*                                    <path*/}
                                    {/*                                        d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z"/>*/}
                                    {/*                                </svg>*/}
                                    {/*                            </td>*/}
                                    {/*                        </tr>*/}
                                    {/*                        <tr className='border-bottom cThemeBorder1 cThemeBorder2 border-1'>*/}
                                    {/*                            <th scope="row">1</th>*/}
                                    {/*                            <td>عضو شورای سیاستگذاری علمی نوین پزشکی</td>*/}
                                    {/*                            <td>دکتر سید شهاب الدین صدر ـ رئیس کل سازمان نظام*/}
                                    {/*                                پزشکی*/}
                                    {/*                            </td>*/}
                                    {/*                            <td>*/}
                                    {/*                                <svg*/}
                                    {/*                                    xmlns="http://www.w3.org/2000/svg" width="16"*/}
                                    {/*                                    height="16" fill="currentColor"*/}
                                    {/*                                    className="bi bi-download cCursorPointer"*/}
                                    {/*                                    viewBox="0 0 16 16">*/}
                                    {/*                                    <path*/}
                                    {/*                                        d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5"/>*/}
                                    {/*                                    <path*/}
                                    {/*                                        d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z"/>*/}
                                    {/*                                </svg>*/}
                                    {/*                            </td>*/}
                                    {/*                            <td>*/}
                                    {/*                                <svg*/}
                                    {/*                                    xmlns="http://www.w3.org/2000/svg" width="16"*/}
                                    {/*                                    height="16" fill="currentColor"*/}
                                    {/*                                    className="bi bi-download cCursorPointer"*/}
                                    {/*                                    viewBox="0 0 16 16">*/}
                                    {/*                                    <path*/}
                                    {/*                                        d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5"/>*/}
                                    {/*                                    <path*/}
                                    {/*                                        d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z"/>*/}
                                    {/*                                </svg>*/}
                                    {/*                            </td>*/}
                                    {/*                        </tr>*/}
                                    {/*                        <tr className='border-bottom cThemeBorder1 cThemeBorder2 border-1'>*/}
                                    {/*                            <th scope="row">1</th>*/}
                                    {/*                            <td>عضو شورای سیاستگذاری علمی نوین پزشکی</td>*/}
                                    {/*                            <td>دکتر سید شهاب الدین صدر ـ رئیس کل سازمان نظام*/}
                                    {/*                                پزشکی*/}
                                    {/*                            </td>*/}
                                    {/*                            <td>*/}
                                    {/*                                <svg*/}
                                    {/*                                    xmlns="http://www.w3.org/2000/svg" width="16"*/}
                                    {/*                                    height="16" fill="currentColor"*/}
                                    {/*                                    className="bi bi-download cCursorPointer"*/}
                                    {/*                                    viewBox="0 0 16 16">*/}
                                    {/*                                    <path*/}
                                    {/*                                        d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5"/>*/}
                                    {/*                                    <path*/}
                                    {/*                                        d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z"/>*/}
                                    {/*                                </svg>*/}
                                    {/*                            </td>*/}
                                    {/*                            <td>*/}
                                    {/*                                <svg*/}
                                    {/*                                    xmlns="http://www.w3.org/2000/svg" width="16"*/}
                                    {/*                                    height="16" fill="currentColor"*/}
                                    {/*                                    className="bi bi-download cCursorPointer"*/}
                                    {/*                                    viewBox="0 0 16 16">*/}
                                    {/*                                    <path*/}
                                    {/*                                        d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5"/>*/}
                                    {/*                                    <path*/}
                                    {/*                                        d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z"/>*/}
                                    {/*                                </svg>*/}
                                    {/*                            </td>*/}
                                    {/*                        </tr>*/}
                                    {/*                        <tr className='border-bottom cThemeBorder1 cThemeBorder2 border-1'>*/}
                                    {/*                            <th scope="row">1</th>*/}
                                    {/*                            <td>عضو شورای سیاستگذاری علمی نوین پزشکی</td>*/}
                                    {/*                            <td>دکتر سید شهاب الدین صدر ـ رئیس کل سازمان نظام*/}
                                    {/*                                پزشکی*/}
                                    {/*                            </td>*/}
                                    {/*                            <td>*/}
                                    {/*                                <svg*/}
                                    {/*                                    xmlns="http://www.w3.org/2000/svg" width="16"*/}
                                    {/*                                    height="16" fill="currentColor"*/}
                                    {/*                                    className="bi bi-download cCursorPointer"*/}
                                    {/*                                    viewBox="0 0 16 16">*/}
                                    {/*                                    <path*/}
                                    {/*                                        d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5"/>*/}
                                    {/*                                    <path*/}
                                    {/*                                        d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z"/>*/}
                                    {/*                                </svg>*/}
                                    {/*                            </td>*/}
                                    {/*                            <td>*/}
                                    {/*                                <svg*/}
                                    {/*                                    xmlns="http://www.w3.org/2000/svg" width="16"*/}
                                    {/*                                    height="16" fill="currentColor"*/}
                                    {/*                                    className="bi bi-download cCursorPointer"*/}
                                    {/*                                    viewBox="0 0 16 16">*/}
                                    {/*                                    <path*/}
                                    {/*                                        d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5"/>*/}
                                    {/*                                    <path*/}
                                    {/*                                        d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z"/>*/}
                                    {/*                                </svg>*/}
                                    {/*                            </td>*/}
                                    {/*                        </tr>*/}
                                    {/*                        <tr className='border-bottom cThemeBorder1 cThemeBorder2 border-1'>*/}
                                    {/*                            <th scope="row">1</th>*/}
                                    {/*                            <td>عضو شورای سیاستگذاری علمی نوین پزشکی</td>*/}
                                    {/*                            <td>دکتر سید شهاب الدین صدر ـ رئیس کل سازمان نظام*/}
                                    {/*                                پزشکی*/}
                                    {/*                            </td>*/}
                                    {/*                            <td>*/}
                                    {/*                                <svg*/}
                                    {/*                                    xmlns="http://www.w3.org/2000/svg" width="16"*/}
                                    {/*                                    height="16" fill="currentColor"*/}
                                    {/*                                    className="bi bi-download cCursorPointer"*/}
                                    {/*                                    viewBox="0 0 16 16">*/}
                                    {/*                                    <path*/}
                                    {/*                                        d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5"/>*/}
                                    {/*                                    <path*/}
                                    {/*                                        d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z"/>*/}
                                    {/*                                </svg>*/}
                                    {/*                            </td>*/}
                                    {/*                            <td>*/}
                                    {/*                                <svg*/}
                                    {/*                                    xmlns="http://www.w3.org/2000/svg" width="16"*/}
                                    {/*                                    height="16" fill="currentColor"*/}
                                    {/*                                    className="bi bi-download cCursorPointer"*/}
                                    {/*                                    viewBox="0 0 16 16">*/}
                                    {/*                                    <path*/}
                                    {/*                                        d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5"/>*/}
                                    {/*                                    <path*/}
                                    {/*                                        d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z"/>*/}
                                    {/*                                </svg>*/}
                                    {/*                            </td>*/}
                                    {/*                        </tr>*/}
                                    {/*                        <tr className='border-bottom cThemeBorder1 cThemeBorder2 border-1'>*/}
                                    {/*                            <th scope="row">1</th>*/}
                                    {/*                            <td>عضو شورای سیاستگذاری علمی نوین پزشکی</td>*/}
                                    {/*                            <td>دکتر سید شهاب الدین صدر ـ رئیس کل سازمان نظام*/}
                                    {/*                                پزشکی*/}
                                    {/*                            </td>*/}
                                    {/*                            <td>*/}
                                    {/*                                <svg*/}
                                    {/*                                    xmlns="http://www.w3.org/2000/svg" width="16"*/}
                                    {/*                                    height="16" fill="currentColor"*/}
                                    {/*                                    className="bi bi-download cCursorPointer"*/}
                                    {/*                                    viewBox="0 0 16 16">*/}
                                    {/*                                    <path*/}
                                    {/*                                        d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5"/>*/}
                                    {/*                                    <path*/}
                                    {/*                                        d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z"/>*/}
                                    {/*                                </svg>*/}
                                    {/*                            </td>*/}
                                    {/*                            <td>*/}
                                    {/*                                <svg*/}
                                    {/*                                    xmlns="http://www.w3.org/2000/svg" width="16"*/}
                                    {/*                                    height="16" fill="currentColor"*/}
                                    {/*                                    className="bi bi-download cCursorPointer"*/}
                                    {/*                                    viewBox="0 0 16 16">*/}
                                    {/*                                    <path*/}
                                    {/*                                        d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5"/>*/}
                                    {/*                                    <path*/}
                                    {/*                                        d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z"/>*/}
                                    {/*                                </svg>*/}
                                    {/*                            </td>*/}
                                    {/*                        </tr>*/}
                                    {/*                        <tr className='border-bottom cThemeBorder1 cThemeBorder2 border-1'>*/}
                                    {/*                            <th scope="row">1</th>*/}
                                    {/*                            <td>عضو شورای سیاستگذاری علمی نوین پزشکی</td>*/}
                                    {/*                            <td>دکتر سید شهاب الدین صدر ـ رئیس کل سازمان نظام*/}
                                    {/*                                پزشکی*/}
                                    {/*                            </td>*/}
                                    {/*                            <td>*/}
                                    {/*                                <svg*/}
                                    {/*                                    xmlns="http://www.w3.org/2000/svg" width="16"*/}
                                    {/*                                    height="16" fill="currentColor"*/}
                                    {/*                                    className="bi bi-download cCursorPointer"*/}
                                    {/*                                    viewBox="0 0 16 16">*/}
                                    {/*                                    <path*/}
                                    {/*                                        d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5"/>*/}
                                    {/*                                    <path*/}
                                    {/*                                        d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z"/>*/}
                                    {/*                                </svg>*/}
                                    {/*                            </td>*/}
                                    {/*                            <td>*/}
                                    {/*                                <svg*/}
                                    {/*                                    xmlns="http://www.w3.org/2000/svg" width="16"*/}
                                    {/*                                    height="16" fill="currentColor"*/}
                                    {/*                                    className="bi bi-download cCursorPointer"*/}
                                    {/*                                    viewBox="0 0 16 16">*/}
                                    {/*                                    <path*/}
                                    {/*                                        d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5"/>*/}
                                    {/*                                    <path*/}
                                    {/*                                        d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z"/>*/}
                                    {/*                                </svg>*/}
                                    {/*                            </td>*/}
                                    {/*                        </tr>*/}
                                    {/*                        <tr className='border-bottom cThemeBorder1 cThemeBorder2 border-1'>*/}
                                    {/*                            <th scope="row">1</th>*/}
                                    {/*                            <td>عضو شورای سیاستگذاری علمی نوین پزشکی</td>*/}
                                    {/*                            <td>دکتر سید شهاب الدین صدر ـ رئیس کل سازمان نظام*/}
                                    {/*                                پزشکی*/}
                                    {/*                            </td>*/}
                                    {/*                            <td>*/}
                                    {/*                                <svg*/}
                                    {/*                                    xmlns="http://www.w3.org/2000/svg" width="16"*/}
                                    {/*                                    height="16" fill="currentColor"*/}
                                    {/*                                    className="bi bi-download cCursorPointer"*/}
                                    {/*                                    viewBox="0 0 16 16">*/}
                                    {/*                                    <path*/}
                                    {/*                                        d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5"/>*/}
                                    {/*                                    <path*/}
                                    {/*                                        d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z"/>*/}
                                    {/*                                </svg>*/}
                                    {/*                            </td>*/}
                                    {/*                            <td>*/}
                                    {/*                                <svg*/}
                                    {/*                                    xmlns="http://www.w3.org/2000/svg" width="16"*/}
                                    {/*                                    height="16" fill="currentColor"*/}
                                    {/*                                    className="bi bi-download cCursorPointer"*/}
                                    {/*                                    viewBox="0 0 16 16">*/}
                                    {/*                                    <path*/}
                                    {/*                                        d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5"/>*/}
                                    {/*                                    <path*/}
                                    {/*                                        d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z"/>*/}
                                    {/*                                </svg>*/}
                                    {/*                            </td>*/}
                                    {/*                        </tr>*/}
                                    {/*                        <tr className='border-bottom cThemeBorder1 cThemeBorder2 border-1'>*/}
                                    {/*                            <th scope="row">1</th>*/}
                                    {/*                            <td>عضو شورای سیاستگذاری علمی نوین پزشکی</td>*/}
                                    {/*                            <td>دکتر سید شهاب الدین صدر ـ رئیس کل سازمان نظام*/}
                                    {/*                                پزشکی*/}
                                    {/*                            </td>*/}
                                    {/*                            <td>*/}
                                    {/*                                <svg*/}
                                    {/*                                    xmlns="http://www.w3.org/2000/svg" width="16"*/}
                                    {/*                                    height="16" fill="currentColor"*/}
                                    {/*                                    className="bi bi-download cCursorPointer"*/}
                                    {/*                                    viewBox="0 0 16 16">*/}
                                    {/*                                    <path*/}
                                    {/*                                        d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5"/>*/}
                                    {/*                                    <path*/}
                                    {/*                                        d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z"/>*/}
                                    {/*                                </svg>*/}
                                    {/*                            </td>*/}
                                    {/*                            <td>*/}
                                    {/*                                <svg*/}
                                    {/*                                    xmlns="http://www.w3.org/2000/svg" width="16"*/}
                                    {/*                                    height="16" fill="currentColor"*/}
                                    {/*                                    className="bi bi-download cCursorPointer"*/}
                                    {/*                                    viewBox="0 0 16 16">*/}
                                    {/*                                    <path*/}
                                    {/*                                        d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5"/>*/}
                                    {/*                                    <path*/}
                                    {/*                                        d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z"/>*/}
                                    {/*                                </svg>*/}
                                    {/*                            </td>*/}
                                    {/*                        </tr>*/}
                                    {/*                        </tbody>*/}
                                    {/*                    </table>*/}
                                    {/*                </div>*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                     <div className='cThemeBg1 cThemeBg2 bg-gradient rounded-4 cBoxShadow1'>
                                        <div className='row align-items-center py-3 px-3'>
                                            <div className='col-12 py-5 overflow-hidden'>
                                                    <h6 className='cThemeColor2 text-center py-5 cAviny lh-lg'>این صفحه
                                                        در حال بروزرسانی می باشد</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                    </main>

                    {footer(this.context)}


                </div>


            </HelmetProvider>
        )
    }
}

export default withRouter(BastehayeAmoozeshi)
