import {Component} from "react";
import simplyContext from "../../../../settings/state/simply";
import withRouter from "../../../main/withRouter"
import {funcNavigate} from "../../../../settings/utils/functions";
import {Helmet, HelmetProvider} from "react-helmet-async";
import {header} from "../../components/header";
import {footer} from "../../components/footer";
import {NavLink} from "react-router-dom";
import {urlDynamic} from "../../../../settings/utils/urlDynamic";
import parse from "html-react-parser"

class Bio extends Component {
    static contextType = simplyContext

    componentDidMount() {
        this.context.fSettings('fBio')
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        funcNavigate(this.props.navigate, this.context)
    }


    render() {
        return (
            <HelmetProvider>
                <Helmet>
                     <title>دکتر شهرام یزدانی / بیوگرافی</title>
                    <meta name="description" content=''/>
                    </Helmet>

                <div
                    className={`container-fluid ${this.context.state.sThemeMode === 'cDark' ? "cBgDark text-white" : "bg-light text-dark"} min-vh-100 ${this.context.state.sThemeColor}`}>

                    {header(this.context)}

                    {this.context.state.sBio !== null ? (
                        <main>

                            <section>
                                <div className="row px-2 px-md-5 my-4 mb-5">
                                    <div className='col-12'>
                                        <div className='mb-3'>
                                            <NavLink to={urlDynamic.path.safheAsli}
                                                     className={(e) => e['isActive'] === true ? `${this.context.state.sThemeMode === 'cDark' ? "text-white" : "text-dark"}` : `${this.context.state.sThemeMode === 'cDark' ? "text-white" : "text-dark"} cHover3 cTransition`}>صفحه
                                                اصلی</NavLink>
                                            <span className='mx-2'>/</span>
                                            <span className='fw-bold'>بیوگرافی</span>
                                        </div>
                                        {this.context.state.sBio.ser !== null ? (
                                        <div className='cThemeBg1 cThemeBg2 bg-gradient rounded-4 cBoxShadow1'>
                                            <div className='row align-items-center py-3 px-4'>
                                                <div className='col-12 overflow-hidden'>
                                                        <div className='cThemeColor1 cThemeColor3 cLineHeight1 cTextJustify'>
                                                                 <p className='mb-0'>
                                                                     {parse(this.context.state.sBio.ser.title)}
                                                            </p>
                                                        </div>
                                                </div>
                                            </div>
                                        </div>
                                        ):null}
                                    </div>
                                </div>
                            </section>

                        </main>
                    ) : null}

                    {footer(this.context)}


                </div>


            </HelmetProvider>
        )
    }
}

export default withRouter(Bio)

