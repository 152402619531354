import {Classic} from "@theme-toggles/react";
import "@theme-toggles/react/css/Classic.css";
import {menu} from "./menu";
import {modalChangeTheme} from "./modalChangeTheme";
import {Link, NavLink} from "react-router-dom";
import {urlDynamic} from "../../../settings/utils/urlDynamic";
import { LazyLoadImage } from 'react-lazy-load-image-component';

export const header = (context) => {
    return (
        <header className='sticky-top'>
            <div className="cThemeBg1 cThemeColor1 cBoxShadow1 py-3 row px-2 px-md-5">
                <div className='col-12'>
                    <div className='d-md-flex align-items-center'>
                        <h1 className='flex-grow-1 cFontSize14 cAviny lh-lg d-block d-md-flex mb-3 mb-md-0'>
                            <NavLink to={urlDynamic.path.safheAsli} className='cThemeColor1'>
                                {context.state.sSettings !== null ? (
                                    context.state.sSettings.ser.title
                                ) : 'دکتر شهرام یزدانی'}
                            </NavLink>
                        </h1>
                        <div className='d-flex float-md-end align-items-center'>
                            <span onClick={() => context.fOffConvas()} className='flex-grow-1 d-md-none'>
                            <svg
                                xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor"
                                className="bi bi-justify ms-2 cCursorPointer cHover2 cTransition"
                                viewBox="0 0 16 16">
                                <path fillRule="evenodd"
                                      d="M2 12.5a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5m0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5m0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5m0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5"/>
                            </svg>
                            </span>
                            <Link className='ms-2 cCursorPointer cHover2 cTransition' to='https://scholar.google.com/citations?user=50wFdUUAAAAJ&hl=en' target='_blank'>
                            <LazyLoadImage effect="blur" src='/images/scolar.webp'
                                 height='30px' width='30px'
                                 className='img-fluid'
                                  alt='تصویر'/>
                            </Link>
                            <Link className='ms-2 cCursorPointer cHover2 cTransition' to='https://www.linkedin.com/in/shahram-yazdani-19974076/' target='_blank'>
                            <LazyLoadImage effect="blur" src='/images/linkdin.webp'
                                   height='30px' width='30px'
                                 className='img-fluid'
                                 alt='تصویر'/>
                            </Link>
                            <Link className='ms-2 cCursorPointer cHover2 cTransition' to='https://www.researchgate.net/profile/Shahram-Yazdani-3' target='_blank'>
                                   <LazyLoadImage effect="blur" src='/images/research.webp'
                                          height='30px' width='30px'
                                 className='img-fluid'
                                 alt='تصویر'/>
                            </Link>
                            <svg
                                onClick={() => context.fShowModal('modalChangeTheme')}
                                xmlns="http://www.w3.org/2000/svg" width="27" height="27"
                                fill="currentColor"
                                className="bi bi-palette theme-toggle cCursorPointer cHover2 cTransition ms-2"
                                viewBox="0 0 16 16">
                                <path
                                    d="M8 5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3m4 3a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3M5.5 7a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m.5 6a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3"/>
                                <path
                                    d="M16 8c0 3.15-1.866 2.585-3.567 2.07C11.42 9.763 10.465 9.473 10 10c-.603.683-.475 1.819-.351 2.92C9.826 14.495 9.996 16 8 16a8 8 0 1 1 8-8m-8 7c.611 0 .654-.171.655-.176.078-.146.124-.464.07-1.119-.014-.168-.037-.37-.061-.591-.052-.464-.112-1.005-.118-1.462-.01-.707.083-1.61.704-2.314.369-.417.845-.578 1.272-.618.404-.038.812.026 1.16.104.343.077.702.186 1.025.284l.028.008c.346.105.658.199.953.266.653.148.904.083.991.024C14.717 9.38 15 9.161 15 8a7 7 0 1 0-7 7"/>
                            </svg>
                            <Classic toggle={(e) => context.fThemeMode(e)}
                                     toggled={context.state.sThemeMode === 'cDark'}
                                     className='d-inline-block ms-2 cThemeIcon p-0 cHover2 cTransition'
                                     title=''/>
                            <span onClick={() => context.fOffConvas()}
                                  className='cCursorPointer cThemeHover1 cTransition ms-4 d-none d-md-inline-block'>
                            <svg
                                xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor"
                                className="bi bi-justify"
                                viewBox="0 0 16 16">
                    <path fillRule="evenodd"
                          d="M2 12.5a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5m0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5m0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5m0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5"/>
                </svg>
                </span>
                        </div>
                    </div>
                </div>


                {menu(context)}
                {modalChangeTheme(context)}
            </div>
        </header>
    )
}