import toast from "react-hot-toast";
import {Fragment} from "react";

export const toastAlerts = (settings) => {
    return (
        <Fragment>
            {settings !== undefined ? (
                settings.data.redirect !== "true" ? (
                    settings.data.message !== undefined ? (
                        settings.data.code === 'error' || settings.data.code === 'success' ? (
                            toast(settings.data.message, {
                                position: 'top-left',
                                duration: 3000,
                                className: `cFontSize12 bg-gradient text-white ${settings.data.code === "error" ? "bg-danger" : "bg-success"}`
                            })
                        ) : null
                    ) : null
                ) : null
            ) : null}
        </Fragment>
    )
}
