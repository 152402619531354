import React, {Component, Fragment} from 'react';
import {Routes, Route} from "react-router-dom"
import {urlDynamic} from "../../settings/utils/urlDynamic";
import Home from "../components/home/main/Home";
import NotFound from "../../settings/utils/NotFound";
import Error500 from "../components/error500/main/Error500";
import Bio from "../components/bio/main/Bio";
import SavabeghModiriati from "../components/savabeghModiriati/main/SavabeghModiriati";
import Eftekharat from "../components/eftekharat/main/Eftekharat";
import SlideHa from "../components/slideHa/main/SlideHa";
import SavabeghResanei from "../components/savabeghResanei/main/SavabeghResanei";
import MaghalatFarsi from "../components/maghalatFarsi/main/MaghalatFarsi";
import MaghalatEn from "../components/maghalatEn/main/MaghalatEn";
import Talifat from "../components/talifat/main/Talifat";
import DarsGoftar from "../components/darsGoftar/main/DarsGoftar";
import MarjaiatElmi from "../components/marjaiatElmi/main/MarjaiatElmi";
import GoftarhayeRahbordi from "../components/goftarHayeRahbordi/main/GoftarhayeRahbordi";
import BateHayeAmoozeshiAnjomanHa from "../components/basteHayeAmoozeshiAnjomanHa/main/BateHayeAmoozeshiAnjomanHa";
import ErteghaAzaeHeyatElmi from "../components/erteghaAzaeHeyatElmi/main/ErteghaAzaeHeyatElmi";
import BastehayeAmoozeshi from "../components/bastehayeAmoozeshi/main/BastehayeAmoozeshi";
import RahErtebati from "../components/rahErtebati/main/RahErtebati";
import TalarGoftegoo from "../components/talarGoftegoo/main/TalarGoftegoo";


class App extends Component {
    render() {
        return (
            <Fragment>
                <Routes>
                    <Route path={urlDynamic.path.safheAsli} element={<Home/>}/>
                    <Route path={urlDynamic.path.bio} element={<Bio/>}/>
                    <Route path={urlDynamic.path.savebeghModiriati} element={<SavabeghModiriati/>}/>
                    <Route path={urlDynamic.path.eftekharat} element={<Eftekharat/>}/>
                    <Route path={urlDynamic.path.slide} element={<SlideHa/>}/>
                    <Route path={urlDynamic.path.maghalatFa} element={<MaghalatFarsi/>}/>
                    <Route path={urlDynamic.path.maghalatEn} element={<MaghalatEn/>}/>
                    <Route path={urlDynamic.path.talifat} element={<Talifat/>}/>
                    <Route path={urlDynamic.path.darsGoftar} element={<DarsGoftar/>}/>
                    <Route path={urlDynamic.path.marjaiatElmi} element={<MarjaiatElmi/>}/>
                    <Route path={urlDynamic.path.goftarHayeRahbordi} element={<GoftarhayeRahbordi/>}/>
                    <Route path={urlDynamic.path.bastehayeAmozeshiAnjomanha} element={<BateHayeAmoozeshiAnjomanHa/>}/>
                    <Route path={urlDynamic.path.erteghaAzaeElmi} element={<ErteghaAzaeHeyatElmi/>}/>
                    <Route path={urlDynamic.path.bastehayeAmoozeshi} element={<BastehayeAmoozeshi/>}/>
                    <Route path={urlDynamic.path.ertebatBaMa} element={<RahErtebati/>}/>
                    <Route path={urlDynamic.path.talarGoftegoo} element={<TalarGoftegoo/>}/>
                    <Route path={urlDynamic.path.savabeghResanei} element={<SavabeghResanei/>}/>
                    <Route path={urlDynamic.path.error500} element={<Error500/>}/>
                    <Route path={'*'} element={<NotFound/>}/>
                </Routes>
            </Fragment>
        )
    }
}

export default App