export const urlDynamic = {
    path: {
        safheAsli: "/",
        bio: "/بیوگرافی",
        savebeghModiriati: "/سوابق-مدیریتی",
        eftekharat: "/افتخارات",
        slide: "/اسلایدها",
        maghalatFa: "/مقالات-فارسی",
        maghalatEn: "/مقالات-انگلیسی",
        talifat: "/تالیفات",
        darsGoftar: "/درسگفتارها",
        marjaiatElmi: "/مجموعه-سخنرانی-مرجعیت-علمی",
        goftarHayeRahbordi: "/گفتارهای-راهبردی-در-حوزه-سیاستگذاری-سلامت",
        bastehayeAmozeshiAnjomanha: "/بسته-های-آموزشی-انجمن-ها",
        erteghaAzaeElmi: "/ارتقاء-اعضای-هیات-علمی",
        bastehayeAmoozeshi: "/بسته-های-آموزشی",
        ertebatBaMa: "/ارتباط-با-ما",
        talarGoftegoo: "/تالار-گفتگو",
        savabeghResanei: "/سوابق-رسانه-ای",
        rahErtebati: "/راه-ارتباطی",
        error500: "/server-error",
    },
}
