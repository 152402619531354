import React, {Component} from "react";
import {range} from "lodash";
import simplyContext from "../../../settings/state/simply";

class PaginationFilter extends Component {
    static contextType = simplyContext;

    render() {
        const dataCount = this.props.state.pageCount;
        const dataPage = this.props.state.pageNumber;
        const dataLimit = this.props.state.pageSize;
        const id = this.props.id;


        let totalPages = Math.ceil(dataCount / dataLimit);
        let startPage = Math.max(dataPage - 2, 1);
        let endPage = Math.min(startPage + 4, totalPages);

        let pageRanges = range(1, (dataCount / dataLimit) + 1)

        if (endPage - startPage + 1 < 5) {
            startPage = Math.max(endPage - 4, 1);
        }

        let pages = range(startPage, endPage + 1);

        return (
            pages.length > 1 ? (
                <nav aria-label="Page navigation example">
                    <ul className="pagination justify-content-center justify-content-lg-end">
                        {dataPage !== 1 ? (
                            id === null ? (
                                <li onClick={() => dataPage !== 1 ? this.props.func({pageNumber:1}) : null} className="page-item">
                                <span className='page-link cThemeBg1 cThemeBorder1 cThemeBorder2 cThemeHover1 cThemeColor1 cThemeColor3' aria-hidden="true">&laquo;</span>
                            </li>
                                ) : (
                                 <li onClick={() => dataPage !== 1 ? this.props.func({pageNumber:1}, id) : null} className="page-item">
                                <span className='page-link cThemeBg1 cThemeBorder1 cThemeBorder2 cThemeHover1 cCursorPointer cThemeColor1 cThemeColor3' aria-hidden="true">&laquo;</span>
                            </li>
                                )
                        ) : null}

                        {pages.map((page, index) => (
                            id === null ? (
                            <li key={index} className="page-item"
                                onClick={() => page !== dataPage ? this.props.func({pageNumber:page}) : null}>
                                  <span
                                      className={`page-link ${page === dataPage ? "cThemeBg1 cThemeBg2 fw-bold cThemeBorder1 cThemeBorder2 cThemeColor2 cCursorDefault" : "cCursorPointer cThemeBorder1 cThemeBorder2 cThemeHover1 cThemeBg1 cThemeBg2 cThemeColor1 cThemeColor3"}`}>
                                    {page}
                                  </span>
                            </li>
                            ) :(
                                 <li key={index} className="page-item"
                                onClick={() => page !== dataPage ? this.props.func({pageNumber:page}, id) : null}>
                                  <span
                                      className={`page-link ${page === dataPage ? "cThemeBg1 cThemeBg2 fw-bold cThemeBorder1 cThemeBorder2 cThemeHover1 cThemeColor2 cCursorDefault" : "cCursorPointer cThemeBorder1 cThemeBorder2 cThemeHover1 cThemeBg1 cThemeBg2 cThemeColor1 cThemeColor3"}`}>
                                    {page}
                                  </span>
                            </li>
                            )
                        ))}

                        {dataPage !== pageRanges[pageRanges.length - 1] ? (
                            id === null ? (
                            <li onClick={() => dataPage !== pageRanges[pageRanges.length - 1] ? this.props.func({pageNumber:pageRanges[pageRanges.length - 1]}) : null}
                                className="page-item">
                                <span className='page-link cThemeBg1 cThemeBg2 cThemeBorder1 cThemeBorder2 cThemeHover1 cCursorPointer cThemeColor1 cThemeColor3' aria-hidden="true">&raquo;</span>
                            </li>
                            ):(
                              <li onClick={() => dataPage !== pageRanges[pageRanges.length - 1] ? this.props.func({pageNumber:pageRanges[pageRanges.length - 1]}, id) : null}
                                className="page-item">
                                <span className='page-link cThemeBg1 cThemeBg2 cThemeBorder1 cThemeBorder2 cThemeHover1 cCursorPointer cThemeColor1 cThemeColor3' aria-hidden="true">&raquo;</span>
                            </li>
                            )
                        ) : null}
                    </ul>
                </nav>
            ) : null
        );
    }
}

export default PaginationFilter;