import {Component} from "react";
import simplyContext from "../../../../settings/state/simply";
import withRouter from "../../../main/withRouter"
import {funcNavigate} from "../../../../settings/utils/functions";
import {Helmet, HelmetProvider} from "react-helmet-async";
import {header} from "../../components/header";
import {footer} from "../../components/footer";
import {NavLink} from "react-router-dom";
import {urlDynamic} from "../../../../settings/utils/urlDynamic";
import PaginationFilter from "../../components/PaginationFilter";

class SavabeghResanei extends Component {
    static contextType = simplyContext

    componentDidMount() {
        this.context.fSettings('fSavabeghResanei')
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        funcNavigate(this.props.navigate, this.context)
    }


    render() {
        return (
            <HelmetProvider>
                <Helmet>
                    <title>دکتر شهرام یزدانی / سوابق رسانه ای</title>
                    <meta name="description" content=''/>
                </Helmet>

                <div
                    className={`container-fluid ${this.context.state.sThemeMode === 'cDark' ? "cBgDark text-white" : "bg-light text-dark"} min-vh-100 ${this.context.state.sThemeColor}`}>

                    {header(this.context)}

                    {this.context.state.sSavabeghResanei !== null ? (
                        <main>

                            <section>
                                <div className="row px-2 px-md-5 my-4 mb-5">
                                    <div className='col-12'>
                                        <div className='mb-3'>
                                            <NavLink to={urlDynamic.path.safheAsli}
                                                     className={(e) => e['isActive'] === true ? `${this.context.state.sThemeMode === 'cDark' ? "text-white" : "text-dark"}` : `${this.context.state.sThemeMode === 'cDark' ? "text-white" : "text-dark"} cHover3 cTransition`}>صفحه
                                                اصلی</NavLink>
                                            <span className='mx-2'>/</span>
                                            <span className='fw-bold'>سوابق رسانه ای</span>
                                        </div>
                                          {this.context.state.sSavabeghResanei.ser !== null ? (
                                        <div className='cThemeBg1 cThemeBg2 bg-gradient rounded-4 cBoxShadow1'>
                                            <div className='row align-items-center py-3 px-4'>
                                                <div className='col-12 overflow-hidden'>
                                                        <div className="table-responsive lh-lg">
                                                            <table
                                                                className="table text-center cThemeColor1 cThemeColor3 align-middle">
                                                                <thead>

                                                                <tr className='border-bottom cThemeBorder1 cThemeBorder2 cThemeColor2 cAviny border-1'>
                                                                    <th className='tablThDefault px-1 fw-normal'
                                                                        scope="col">ردیف
                                                                    </th>
                                                                    <th className='tablThDefault cPadding2 fw-normal'
                                                                        scope="col">عنوان برنامه
                                                                    </th>
                                                                    <th className='tablThDefault cPadding2 fw-normal'
                                                                        scope="col">نوع برنامه
                                                                    </th>
                                                                    <th className='tablThDefault cPadding2 fw-normal'
                                                                        scope="col">نقش در برنامه
                                                                    </th>
                                                                    <th className='tablThDefault cPadding2 fw-normal'
                                                                        scope="col">موضوع برنامه
                                                                    </th>
                                                                    <th className='tablThDefault cPadding2 fw-normal'
                                                                        scope="col">شبکه
                                                                    </th>
                                                                    <th className='tablThDefault cPadding2 fw-normal'
                                                                        scope="col">تاریخ و مدت پخش
                                                                    </th>
                                                                    <th className='tablThDefault cPadding2 fw-normal'
                                                                        scope="col">تعداد برنامه
                                                                    </th>
                                                                    <th className='tablThDefault cPadding2 fw-normal'
                                                                        scope="col">تهیه کننده برنامه
                                                                    </th>
                                                                </tr>
                                                                </thead>
                                                                <tbody className='cFontSize12'>
                                                                 {this.context.state.sSavabeghResanei.ser.map((p, index) => (
                                                                <tr key={index} className='border-bottom cThemeBorder1 cThemeBorder2 border-1'>
                                                                    <th scope="row">
                                                                                   {(index + (this.context.state.sSavabeghResanei.pageNumber - 1) * this.context.state.sSavabeghResanei.pageSize) + 1}
                                                                    </th>
                                                                    <td>
                                                                        {p.title || '-'}
                                                                    </td>
                                                                    <td>
                                                                        {p.noeBarname || '-'}
                                                                    </td>
                                                                    <td>
                                                                         {p.naghshBarname || '-'}
                                                                    </td>
                                                                    <td>
                                                                         {p.mozooBarname || '-'}
                                                                    </td>
                                                                    <td>
                                                                        {p.shabake || '-'}
                                                                    </td>
                                                                    <td>
                                                                        {p.tarikh || '-'}
                                                                    </td>
                                                                     <td>
                                                                        {p.tedad || '-'}
                                                                    </td>
                                                                     <td>
                                                                        {p.tahie || '-'}
                                                                    </td>
                                                                </tr>
                                                                          ))}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                         <div className='my-3'>
                                                            <PaginationFilter func={this.context.fSavabeghResanei}
                                                                              state={this.context.state.sSavabeghResanei}/>
                                                        </div>
                                                </div>
                                            </div>
                                        </div>
                                                        ):null}
                                    </div>
                                </div>
                            </section>

                        </main>
                    ) : null}

                    {footer(this.context)}


                </div>


            </HelmetProvider>
        )
    }
}

export default withRouter(SavabeghResanei)

