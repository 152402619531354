import React from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';

const withRouter = WrappedComponent => {
  return props => {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();


    return <WrappedComponent {...props} params={params} location={location} navigate={navigate} />;
  };
};

export default withRouter;