import {Component} from "react";
import simplyContext from "../state/simply";

class Preloader extends Component {
    static contextType = simplyContext

    render() {
        return (
            <div
                className={this.context.state.sPreloader === true ? 'position-fixed w-100 min-vh-100 top-0 start-0 d-flex align-items-center justify-content-center cZindex9999 bg-dark' : "d-none"}>
                <div className='row'>
                    <div className='col-12 text-center'>
                        <div className="textWrapper">
                            <p className="text mb-0">Loading...</p>
                            <div className="invertbox"></div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Preloader