import {Component} from "react";
import simplyContext from "../state/simply";
import {Helmet, HelmetProvider} from "react-helmet-async";
import {NavLink} from "react-router-dom";
import {urlDynamic} from "./urlDynamic";
import {funcNavigate} from "./functions";
import withRouter from "../../app/main/withRouter";
import {footer} from "../../app/components/components/footer";
import {header} from "../../app/components/components/header";


class NotFound extends Component {
    static contextType = simplyContext

    componentDidMount() {
        this.context.fReset()
        this.context.fPreloader(false)
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        funcNavigate(this.props.navigate, this.context)
    }

    render() {
        return (
            <HelmetProvider>
                <Helmet>
                     <title>دکتر شهرام یزدانی / صفحه ای یافت نشد</title>
                    <meta name="description" content=''/>
                </Helmet>

                <div
                    className={`container-fluid ${this.context.state.sThemeMode === 'cDark' ? "cBgDark text-white" : "bg-light text-dark"} min-vh-100 ${this.context.state.sThemeColor === null ? "cTheme15": this.context.state.sThemeColor}`}>

                    {header(this.context)}


                    <main>

                        <section>
                            <div className="row px-2 px-md-5 my-4 mb-5">
                                <div className='col-12'>
                                    <div className='mb-3'>
                                        <NavLink to={urlDynamic.path.safheAsli}
                                                 className={(e) => e['isActive'] === true ? `${this.context.state.sThemeMode === 'cDark' ? "text-white" : "text-dark"}` : `${this.context.state.sThemeMode === 'cDark' ? "text-white" : "text-dark"} cHover3 cTransition`}>صفحه
                                            اصلی</NavLink>
                                        <span className='mx-2'>/</span>
                                        <span className='fw-bold'>صفحه ای یافت نشد</span>
                                    </div>
                                     <div className='cThemeBg1 cThemeBg2 bg-gradient rounded-4 cBoxShadow1'>
                                        <div className='row align-items-center py-3 px-3'>
                                            <div className='col-12 py-5 overflow-hidden'>
                                                    <h6 className='cThemeColor2 text-center py-5 cAviny lh-lg'>
                                                        صفحه ای یافت نشد</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </main>

                    {footer(this.context)}


                </div>


            </HelmetProvider>
        )
    }
}

export default withRouter(NotFound)

