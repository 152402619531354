import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import {LazyLoadImage} from 'react-lazy-load-image-component';
import {Link} from "react-router-dom";

export const bookCarusel = (qs) => {
    const responsive = {
        superLargeDesktop: {
            breakpoint: {max: 4000, min: 3000},
            items: 5
        },
        desktop: {
            breakpoint: {max: 3000, min: 1024},
            items: 5
        },
        tablet: {
            breakpoint: {max: 1024, min: 464},
            items: 2
        },
        mobile: {
            breakpoint: {max: 464, min: 0},
            items: 1
        }
    };


    return (
        <Carousel
            showDots={false}
            className='mt-2'
            infinite={true}
            draggable={false}
            rtl={true}
            autoPlay={true}
            autoPlaySpeed={4000}
            itemClass="p-3 text-center"
            responsive={responsive}>
            {qs.map((p, index) => (
                <Link to={p.image} target='_blank' key={index} className='cCursorPointer cThemeColor1 cThemeColor3 cThemeHover1 cTransition'>
                    <div className='cSliderCalc2 position-relative'>
                        <div
                            className='position-absolute w-100 h-100 top-0 start-0'>
                             <LazyLoadImage effect="blur" src={p.imageThumbnail} className='img-fluid w-100 rounded'
                                   alt='تصویر'/>
                        </div>
                    </div>
                    <p className='cFontSize12 mb-0 d-inline-block cLineHeight2 mt-2'>
                        {p.title}
                    </p>
                </Link>
            ))}
        </Carousel>
    );
};
