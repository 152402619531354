import {Component, Fragment} from "react";
import App from "./App";
import {Toaster} from 'react-hot-toast';
import {BrowserRouter} from "react-router-dom";
import {BASE_NAME} from "../../settings/utils/config";
import Preloader from "../../settings/utils/Preloader";


class MainApp extends Component {
    render() {
        return (
            <Fragment>
                <BrowserRouter future={{v7_startTransition: true, v7_relativeSplatPath: true,}} basename={BASE_NAME}>
                    <App/>
                    <Preloader/>
                </BrowserRouter>
                <Toaster/>
            </Fragment>
        )
    }
}

export default MainApp