import {createContext} from "react";

const simplyContext = createContext({
    state: {},
    fResetNavigate: () => {},
    fNavigate: () => {},
    fPreloader: () => {},
    fThemeMode: () => {},
    fShowModal: () => {},
    fThemeColor: () => {},
    fOffConvas: () => {},
    fReset: () => {},
    fSettings: () => {},
    fBastehayeAmoozeshi: () => {},
    fBateHayeAmoozeshiAnjomanHa: () => {},
    fBio: () => {},
    fDarsGoftar: () => {},
    fEftekharat: () => {},
    fErteghaAzaeHeyatElmi: () => {},
    fGoftarhayeRahbordi: () => {},
    fHome: () => {},
    fMaghalatEn: () => {},
    fMaghalatFarsi: () => {},
    fMarjaiatElmi: () => {},
    fRahErtebati: () => {},
    fSavabeghModiriati: () => {},
    fSavabeghResanei: () => {},
    fSlideHa: () => {},
    fTalarGoftegoo: () => {},
    fTalifat: () => {},
    fNotPermission: () => {},
})

export default simplyContext