import {Component} from "react";
import simplyContext from "../../../../settings/state/simply";
import withRouter from "../../../main/withRouter"
import {funcNavigate} from "../../../../settings/utils/functions";
import {Helmet, HelmetProvider} from "react-helmet-async";
import {bookCarusel} from "../components/bookCarusel";
import {header} from "../../components/header";
import {footer} from "../../components/footer";
import {Link, NavLink} from "react-router-dom";
import {urlDynamic} from "../../../../settings/utils/urlDynamic";
import CountUp from "react-countup";
import {LazyLoadImage} from 'react-lazy-load-image-component';

class Home extends Component {
    static contextType = simplyContext

    componentDidMount() {
        this.context.fSettings('fHome')
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        funcNavigate(this.props.navigate, this.context)
    }


    render() {
        return (
            <HelmetProvider>
                <Helmet>
                    <title>دکتر شهرام یزدانی / صفحه اصلی</title>
                    <meta name="description" content=''/>
                    <link rel="preload" as="image" href="/images/baner.webp"/>
                </Helmet>

                <div
                    className={`container-fluid ${this.context.state.sThemeMode === 'cDark' ? "cBgDark text-white" : "bg-light text-dark"} min-vh-100 ${this.context.state.sThemeColor}`}>

                    {header(this.context)}

                    {this.context.state.sHome !== null ? (
                        <main>

                            <section>
                                <div className="row px-2 px-md-5 mt-4">
                                    <div className='col-12'>
                                        <div className='cThemeBg1 cThemeBg2 bg-gradient rounded-4 cBoxShadow1'>
                                            <div className='row px-3'>
                                                <div
                                                    className='col-12 col-xl py-4 align-self-center position-relative overflow-hidden'>
                                                    <div className='cThemeColor1 cThemeColor3 cLineHeight1 cTextJustify pe-xl-3'>
                                                    <span className='cAviny cThemeColor2'>
                                                    بیوگرافی:
                                                    </span>
                                                        <NavLink to={urlDynamic.path.bio}
                                                                 className="float-end cThemeColor1 cThemeColor3 d-inline-block cCursorPointer cThemeHover1 cTransition">مشاهده
                                                            همه +
                                                        </NavLink>
                                                        <hr className='my-0'/>
                                                        <p className='mb-0'>
                                                            {this.context.state.sHome.ser.bio}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className='col-12 col-xl-3 pt-4 align-self-end text-center'>
                                                    <div className='cSliderCalc1 position-relative'>
                                                        <div
                                                            className='position-absolute w-100 h-100 top-0 start-0'>
                                                            <LazyLoadImage effect="blur" src='/images/baner.webp'
                                                                           className='img-fluid w-100'
                                                                           alt='تصویر'/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>

                            <section>
                                <div className='row mt-4 px-2 px-md-5'>
                                    <div className='col-12'>
                                        <div className='row text-center overflow-hidden'>
                                            <div className='col-12 mb-4 col-md-6 col-lg-2 mb-lg-0'>
                                                <p className='fs-2 mb-0'>
                                                    <CountUp
                                                        start={0}
                                                        separator=''
                                                        end={this.context.state.sHome.ser.ketabHa}
                                                        duration={1.5}

                                                    />
                                                </p>
                                                <p className='mb-0'>مجموع کتاب ها</p>
                                            </div>

                                            <div className='col-12 mb-4 col-md-6 col-lg-2 mb-lg-0'>
                                                <p className='fs-2 mb-0'>
                                                    <CountUp
                                                        start={0}
                                                        separator=''
                                                        end={this.context.state.sHome.ser.sokhanrani}
                                                        duration={1.5}

                                                    />
                                                </p>
                                                <p className='mb-0'>تعداد سخنرانی ها</p>
                                            </div>
                                            <div className='col-12 mb-4 col-md-6 col-lg-2 mb-lg-0'>
                                                <p className='fs-2 mb-0'>
                                                    <CountUp
                                                        start={0}
                                                        separator=''
                                                        end={this.context.state.sHome.ser.dorehaSaat}
                                                        duration={1.5}

                                                    />
                                                </p>
                                                <p className='mb-0'>مجموع ساعت دوره ها</p>
                                            </div>
                                            <div className='col-12 mb-4 col-md-6 col-lg-2 mb-lg-0'>
                                                <p className='fs-2 mb-0'>
                                                    <CountUp
                                                        start={0}
                                                        separator=''
                                                        end={this.context.state.sHome.ser.dorehaDaghighe}
                                                        duration={1.5}

                                                    />
                                                </p>
                                                <p className='mb-0'>مجموع دقیقه دوره ها</p>
                                            </div>
                                            <div className='col-12 mb-4 mb-md-0 col-md-6 col-lg-2'>
                                                <p className='fs-2 mb-0'>
                                                    <CountUp
                                                        start={0}
                                                        separator=''
                                                        end={this.context.state.sHome.ser.maghalat}
                                                        duration={1.5}

                                                    />
                                                </p>
                                                <p className='mb-0'>مجموعه مقالات</p>
                                            </div>
                                            <div className='col-12 col-md-6 col-lg-2'>
                                                <p className='fs-2 mb-0'>
                                                    <CountUp
                                                        start={0}
                                                        separator=''
                                                        end={this.context.state.sHome.ser.tahlil}
                                                        duration={1.5}

                                                    />
                                                </p>
                                                <p className='mb-0'>تحلیل سیاستی</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>

                            {this.context.state.sHome.ser.talifat !== null ? (
                                <section>
                                    <div className='row mt-4 py-5 px-2 px-md-5 cThemeBg1 cThemeBg2 bg-gradient cBoxShadow1'>
                                        <div className='col-12 overflow-hidden'>
                                            <div className='cThemeColor1 cThemeColor3'>
                                                <h3 className='fs-5 d-inline-block cAviny cThemeColor2'>گزیده ای از
                                                    تالیفات</h3>
                                                <NavLink to={urlDynamic.path.talifat}
                                                         className="float-end cThemeColor1 cThemeColor3 d-inline-block cCursorPointer cThemeHover1 cTransition">مشاهده
                                                    همه +
                                                </NavLink>
                                                {bookCarusel(this.context.state.sHome.ser.talifat)}
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            ) : null}

                            <section>
                                <div className='row cPadding1 px-2 px-md-5 overflow-hidden'>
                                    <div className='col-12 mb-3 col-sm-6 col-lg-3 mb-lg-0 text-center'>
                                        <NavLink to={urlDynamic.path.slide}
                                                 className='d-block cThemeBg1 bg-gradient cThemeColor1 p-3 rounded cBoxShadow1 cCursorPointer cThemeHover1 cTransition'>
                                            اسلایدها
                                        </NavLink>
                                    </div>
                                    <div className='col-12 mb-3 col-sm-6 col-lg-3 mb-lg-0 text-center'>
                                        <NavLink to={urlDynamic.path.maghalatFa}
                                                 className='d-block cThemeBg1 bg-gradient cThemeColor1 p-3 rounded cBoxShadow1 cCursorPointer cThemeHover1 cTransition'>
                                            مقالات فارسی
                                        </NavLink>
                                    </div>
                                    <div className='col-12 mb-3 col-sm-6 col-lg-3 mb-lg-0 text-center'>
                                        <NavLink to={urlDynamic.path.maghalatEn}
                                                 className='d-block cThemeBg1 bg-gradient cThemeColor1 p-3 rounded cBoxShadow1 cCursorPointer cThemeHover1 cTransition'>
                                            مقالات انگلیسی
                                        </NavLink>
                                    </div>
                                    <div className='col-12 mb-3 col-sm-6 col-lg-3 mb-lg-0 text-center'>
                                        <NavLink to={urlDynamic.path.savabeghResanei}
                                                 className='d-block cThemeBg1 bg-gradient cThemeColor1 p-3 rounded cBoxShadow1 cCursorPointer cThemeHover1 cTransition'>
                                            سوابق رسانه ای
                                        </NavLink>
                                    </div>
                                </div>
                            </section>


                            <section>
                                <div
                                    className='row align-items-center py-5 px-2 px-md-5 cThemeBg1 bg-gradient cBoxShadow1 overflow-hidden'>
                                    <div className='col-12 col-sm-6 border-end cBorder1_575 cThemeBorder1 border-1'>
                                        <div className='cThemeColor1'>
                                            <h3 className='fs-5 d-inline-block cAviny cThemeColor2'>افتخارات</h3>
                                            <NavLink to={urlDynamic.path.eftekharat}
                                                     className="float-end cThemeColor1  d-inline-block cCursorPointer cThemeHover1 cTransition">مشاهده
                                                همه +
                                            </NavLink>
                                            <div className='row px-4 mt-3'>
                                                <div className='col-12 text-center'>
                                                    <div className='position-relative'>
                                                         <div className='cSliderCalc3 position-relative'>
                                                        <div
                                                            className='position-absolute w-100 h-100 top-0 start-0'>
                                                            <LazyLoadImage effect="blur" src='/images/image4.webp'
                                                                           className='img-fluid rounded-4 w-100'
                                                                           alt=''/>
                                                        </div>
                                                    </div>
                                                        <Link to={this.context.state.sHome.ser.eftekharatVideo}
                                                              target='_blank'
                                                              className={`cSvgVideoPlayer position-absolute top-50 cPosition1 start-50`}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="50"
                                                                 height="50"
                                                                 fill="currentColor"
                                                                 className="bi bi-play-circle-fill cThemeColor2"
                                                                 viewBox="0 0 16 16">
                                                                <path
                                                                    d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M6.79 5.093A.5.5 0 0 0 6 5.5v5a.5.5 0 0 0 .79.407l3.5-2.5a.5.5 0 0 0 0-.814z"/>
                                                            </svg>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-12 col-sm-6 mt-5 mt-sm-0'>
                                        <div className='cThemeColor1'>
                                            <h3 className='fs-5 d-inline-block cAviny cThemeColor2'>سوابق
                                                مدیریتی</h3>
                                            <NavLink to={urlDynamic.path.savebeghModiriati}
                                                     className="float-end cThemeColor1  d-inline-block cCursorPointer cThemeHover1 cTransition">مشاهده
                                                همه +
                                            </NavLink>
                                            <div className='row px-4 mt-3'>
                                                <div className='col-12 text-center'>
                                                    <div className='cSliderCalc3 position-relative'>
                                                        <div
                                                            className='position-absolute w-100 h-100 top-0 start-0'>
                                                            <LazyLoadImage effect="blur" src='/images/image3.webp'
                                                                           className='img-fluid rounded-4 w-100'
                                                                           alt=''/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>

                            <section>
                                <div className='row mt-2 py-5 px-2 px-md-5'>
                                    <div className='col-12'>
                                        <div
                                            className='cThemeBg1 cThemeBg2 bg-gradient rounded-4 cBoxShadow1 py-4 px-lg-4 overflow-hidden'>
                                            <div className='row pt-3 px-3'>
                                                <div className='col-12 mb-3'>
                                                    <h3 className='fs-5 cAviny text-center cThemeColor2'>درسگفتارها</h3>
                                                </div>
                                                <div className='col-12 mb-4 col-sm-6 col-lg mb-lg-0 text-center'>
                                                    <LazyLoadImage src='/images/dars-goftar/image2.webp'
                                                                   className='img-fluid cWidth50_991 d-block m-auto w-100'
                                                                   alt='تصویر'/>
                                                    <NavLink to={urlDynamic.path.marjaiatElmi}
                                                             className='cThemeColor1 cThemeColor3 d-inline-block cLineHeight2 mt-2 cCursorPointer cThemeHover1 cTransition'>
                                                        مجموعه سخنرانی مرجعیت علمی
                                                    </NavLink>
                                                </div>
                                                <div className='col-12 mb-4 col-sm-6 col-lg mb-lg-0 text-center'>
                                                    <LazyLoadImage src='/images/dars-goftar/image5.webp'
                                                                   className='img-fluid cWidth50_991 d-block m-auto w-100'
                                                                   alt='تصویر'/>
                                                    <NavLink to={urlDynamic.path.goftarHayeRahbordi}
                                                             className='cThemeColor1 cThemeColor3 d-inline-block cLineHeight2 mt-2 cCursorPointer cThemeHover1 cTransition'>
                                                        گفتارهای راهبردی در حوزه
                                                        <br/>
                                                        سیاستگذاری سلامت
                                                    </NavLink>
                                                </div>
                                                <div className='col-12 mb-4 col-sm-6 col-lg mb-lg-0 text-center'>
                                                    <LazyLoadImage src='/images/dars-goftar/image3.webp'
                                                                   className='img-fluid cWidth50_991 d-block m-auto w-100'
                                                                   alt='تصویر'/>
                                                    <NavLink to={urlDynamic.path.bastehayeAmozeshiAnjomanha}
                                                             className='cThemeColor1 cThemeColor3 d-inline-block cLineHeight2 mt-2 cCursorPointer cThemeHover1 cTransition'>
                                                        بسته های آموزشی انجمن ها
                                                    </NavLink>
                                                </div>
                                                <div className='col-12 mb-4 mb-sm-0 col-sm-6 col-lg text-center'>
                                                    <LazyLoadImage src='/images/dars-goftar/image4.webp'
                                                                   className='img-fluid cWidth50_991 d-block m-auto w-100'
                                                                   alt='تصویر'/>
                                                    <NavLink to={urlDynamic.path.erteghaAzaeElmi}
                                                             className='cThemeColor1 cThemeColor3 d-inline-block cLineHeight2 mt-2 cCursorPointer cThemeHover1 cTransition'>
                                                        ارتقاء اعضای هیات علمی
                                                    </NavLink>
                                                </div>
                                                <div
                                                    className='col-12 text-center col-sm-6 offset-sm-3 col-lg offset-lg-0'>
                                                    <LazyLoadImage src='/images/dars-goftar/image1.webp'
                                                                   className='img-fluid cWidth50_991 d-block m-auto w-100'
                                                                   alt='تصویر'/>
                                                    <NavLink to={urlDynamic.path.bastehayeAmoozeshi}
                                                             className='cThemeColor1 cThemeColor3 d-inline-block cLineHeight2 mt-2 cCursorPointer cThemeHover1 cTransition'>
                                                        بسته های آموزشی
                                                    </NavLink>
                                                    <small className='cThemeColor1 cThemeColor3 d-inline-block mt-1 cFontSize12'>ویژه
                                                        اعضای هیات علمی و مدیران نظام
                                                        سلامت</small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>

                        </main>
                    ) : null}

                    {footer(this.context)}


                </div>


            </HelmetProvider>
        )
    }
}

export default withRouter(Home)

